<template class="">
    <CalendarsDropDown
        v-if="showDropdown"
        class="added-to-schedule-dropdown"
        :event-name="title"
        :event-description="eventDescription"
        :start-date="startDate"
        :end-date="endDate"
        :location="location"
        :start-open="showCalendar"
    >
        <template #toggle>
            <div class="added-to-schedule">
                <CheckSvg class="added-to-schedule__check-icon" />
                <span class="added-to-schedule__link f-inter">{{ t.addedToSchedule }}</span>
                <ArrowDownSvg class="added-to-schedule__arrow-down-icon" />
            </div>
        </template>
    </CalendarsDropDown>
    <a
        v-else
        class="add-to-schedule f-inter"
        href="#"
        @click.prevent="handleAddToSchedule"
    >
        <ScheduleSvg class="add-to-schedule__icon" />
        <span class="add-to-schedule__link f-inter">{{ t.addToSchedule }}</span>
    </a>
</template>

<script lang="ts">
const localT = {
    addToSchedule: 'Add to schedule',
    addedToSchedule: 'Added to schedule',
}
</script>

<script setup lang="ts">
import { validator } from '@ts/Util/i18n';
import { PropType } from 'vue';
import CalendarsDropDown from "@components/Calendar/CalendarsDropdown.vue";
import ScheduleSvg from '@bx-icons/regular/bx-calendar-alt.svg';
import CheckSvg from '@bx-icons/regular/bx-check.svg';
import ArrowDownSvg from '@bx-icons/regular/bx-chevron-down.svg';

defineProps({
    t: {
        type: Object as PropType<typeof localT>,
        default: () => localT,
        validator: validator(localT)
    },
    showDropdown: {
        type: Boolean,
        default: false
    },
    title: {
        type: String,
        default: '',
        required: false
    },
    eventDescription: {
        type: String,
        required: true,
    },
    startDate: {
        type: Date,
        required: true,
    },
    endDate: {
        type: Date,
        required: true,
    },
    location: {
        type: String,
        default: '',
        required: false
    },
    showCalendar: {
        type: Boolean,
        default: false
    },
})

const emits = defineEmits([
    'add-to-schedule',
])

const handleAddToSchedule = () => {
    emits('add-to-schedule');
}

</script>

<style scoped>
    .add-to-schedule, .added-to-schedule {
        display: inline-flex;
        align-items: flex-end;
    }

    .add-to-schedule__icon {
        fill: var(--zumba-plum);
        margin-right: 0.3rem;
        padding-top: 0.2rem;
    }

    .theme-strong .add-to-schedule__icon {
        fill: var(--strong-razzmatazz);
    }

    .add-to-schedule__link {
        color: var(--zumba-plum);
        font-weight: 700;
    }

    .theme-strong .add-to-schedule__link {
        color: var(--strong-razzmatazz);
    }

    .added-to-schedule__check-icon {
        padding-top: 0.1rem;
    }

    .added-to-schedule__arrow-down-icon {
        padding-top: 0.2rem;
    }

    .added-to-schedule__link {
        color: var(--zumba-gray-800);
    }

    :deep(.menu-wrapper > a) {
        text-decoration: none;
        color: var(--zumba-plum);
    }

    .theme-strong :deep(.menu-wrapper > a) {
        color: var(--strong-razzmatazz);
    }

    .theme-strong :deep(.menu-wrapper > a) {
        color: var(--strong-razzmatazz);
    }
</style>