<template>
    <Modal
        :show="showModal"
        close-when-click-on-background
        close-when-type-esc
        :show-close-button="false"
        enable-scroll
        mobile-drawer
        :custom-styles="{ modal: { 'padding': '2.5rem'} }"
        @closed="emit('closed')"
    >
        <section class="contact-form-container">
            <div class="contact-form">
                <div class="header">
                    <h2 class="email-instructor">
                        {{ t.emailYourInstructor }}
                    </h2>
                </div>
                <HorizontalSeparator />
                <div class="instructor-summary">
                    <div class="instructor-summary-container">
                        <div class="instructor-image">
                            <ProfilePictureDefault
                                :t="t"
                                :profile-photo-url="instructorProfilePhotoUrl ?? ''"
                                class="instructor-thumb-img"
                            />
                        </div>
                        <div class="instructor-details">
                            <div class="instructor-name">
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {{ instructorDisplayName }}
                                </a>
                            </div>
                            <div class="instructor-location">
                                {{ instructorLocation }}
                            </div>
                        </div>
                    </div>
                </div>
                <HorizontalSeparator />
                <ZInput
                    v-model="formData.name"
                    :label="props.t.nameLabel"
                    :disabled="props.disabled"
                    name="name"
                    type="text"
                    required
                    :error="state.errors.name"
                    @update:model-value="onNameChange"
                />
                <ZInput
                    v-model="formData.email"
                    class="contact-form__email"
                    :label="props.t.emailLabel"
                    :disabled="props.disabled"
                    name="username"
                    type="text"
                    required
                    :error="state.errors.email"
                    @update:model-value="onEmailChange"
                />
                <TextArea
                    v-model="formData.comment"
                    class="contact-form__textarea"
                    :class="[state.errors.comment ? 'contact-form__textarea__error' : '']"
                    :disabled="props.disabled"
                    name="message"
                    label="Message"
                    required
                    :error="state.errors.comment"
                    @update:model-value="onCommentChange"
                />
                <GoogleRecaptcha
                    ref="recaptcha"
                    :site-key="props.recaptchaKey"
                    @loaded="state.loading = false"
                    @canceled="state.loading = false"
                    @failure="(error) => $emit('recaptcha:failure', error)"
                    @successful="onSendMessage"
                />
                <ZButton
                    class="login-form__button-login"
                    variety="optimus"
                    wide="always"
                    :disabled="props.disabled || state.loading"
                    @click="onBeforeSubmit"
                >
                    {{ t.submit }}
                </ZButton>
            </div>
        </section>
    </Modal>
</template>

<script lang="ts" setup>
import { PropType, ref, reactive, Ref } from 'vue'
import ZButton from '@components/Core/ZButton.vue'
import ZInput from '@components/Core/ZInput.vue'
import ProfilePictureDefault from '@modules/InstructorPage/ProfilePictureDefault.vue'
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue'
import Modal from '@components/Core/Modal.vue'
import TextArea from '@components/Core/TextArea.vue'
import GoogleRecaptcha from "@components/Core/GoogleRecaptcha.vue";
import { validateEmail } from '@ts/Util/email';
import { theme, ThemeType } from "@ts/Util/theme";

const emit = defineEmits(['closed', 'on-send-message', 'recaptcha:failure'])

const isZumbaThemed = theme.value === ThemeType.Zumba;

const props = defineProps({
    t: {
        type: Object,
        default: () => ({
            emailLabel: 'Email',
            nameLabel: 'Name',
            emailYourInstructor: 'Email your instructor',
            submit: 'Submit',
            emailSubmitted: 'Email sent to your instructor',
            requiredFieldError: "Required Field.",
            emailError: "You must enter a valid email address to continue.",
        })
    },
    showModal: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    instructorPid: {
        type: String,
        required: true
    },
    instructorDisplayName: {
        type: String,
        required: false,
        default: ''
    },
    instructorProfilePhotoUrl: {
        type: String,
        required: false,
        default: ''
    },
    instructorLocation: {
        type: String,
        required: false,
        default: ''
    },
    requesterName: {
        type: String,
        default: ''
    },
    requesterEmail: {
        type: String,
        default: ''
    },
    recaptchaKey: {
        type: String,
        default: ''
    }
})


const formData = {
    name: props.requesterName || '',
    email: props.requesterEmail || '',
    comment: '',
    recaptchaResponse: '',
};

const recaptcha = ref(null) as Ref<null | typeof GoogleRecaptcha>

const state = reactive({
    errors: {
        name: '',
        email: '',
        comment: ''
    },
    loading: false,
})

const onBeforeSubmit = () => {
    formData.email = formData.email.trim();
    if (!allFieldsValid()) {
        return;
    }
    if (!recaptcha.value) {
        throw new Error('Google Recaptcha was not loaded.')
    }

    state.loading = true

    recaptcha
        .value
        .execute()
        .catch((error) => {
            emit('recaptcha:failure', error);
        })
        .finally(() => state.loading = false);
}

const onSendMessage = (recaptchaResponse) => {
    formData.recaptchaResponse = recaptchaResponse;
    emit('on-send-message', formData);
}

const validateNameEntered = () => {
    if (!formData.name) {
        state.errors.name = props.t.requiredFieldError;
    } else {
        state.errors.name = '';
    }
}

const onCommentChange = () => {
    validateCommentEntered();
}

const validateCommentEntered = () => {
    if (!formData.comment) {
        state.errors.comment = props.t.requiredFieldError;
    } else {
        state.errors.comment = '';
    }
}

const validateEmailFormat = () => {
    const emailRegex = /^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

    if (!emailRegex.test(formData.email)) {
        state.errors.email = props.t.emailError;
    } else {
        state.errors.email = '';
    }
}

const onEmailChange = () => {
    formData.email = formData.email.trim();
    validateEmailFormat();
}

const onNameChange = () => {
    validateNameEntered();
}

const allFieldsValid = () => {
    validateNameEntered();
    validateEmailFormat();
    validateCommentEntered();

    return Object.values(state.errors).every(fieldError => fieldError === '');
}


</script>

<style scoped>

.contact-form-container {
    background: var(--zumba-white);
    height: 100%;
}

.contact-form {
    width: auto;
    margin: 0 auto;
}

.instructor-summary {
    display: flex;
}

.instructor-summary-container {
    display: flex;
    align-items: center;
    max-width: 20.313rem;
    margin: 0 auto;
}

.contact-form :deep(label.zInput__label) {
    text-align: left;
}


.email-instructor {
    font-size: 1.5rem;
    text-align: center;
}

.instructor-image {
    width: 3.375rem;
    height: 3.75rem;
}

.instructor-details {
    flex: 1;
    padding: 1.25rem;
}

.instructor-name {
    line-height: 2;
}

.instructor-location {
    text-align: left;
}

.contact-form__email {
    padding-top: 0.25rem;
}

.contact-form__textarea {
    height: 10.625rem;
    padding: 0.25rem 0;
}

.contact-form__textarea:deep(textarea) {
    height: 100%;
    margin: 0;
}

.contact-form__textarea:deep(p) {
    font-weight: normal;
    font-size: .75rem;
    margin: 0;
}

.contact-form__textarea:deep(div) {
    display: none;
}

.contact-form__textarea__error {
    margin-bottom: 2rem;
}

.theme-zumba .contact-form__textarea__error:deep(p) {
    font-family: var(--zumba-font-default);
}

svg.profile-default-image-img {
    width: 3.375rem;
    height: 3.375rem;
}

.contact-form__textarea::placeholder  {
    color: var(--zumba-gray-400);
    position: absolute;
    top: 1rem;
    bottom: 0;
    left: 1rem;
}

.instructor-thumb-img {
    transform: scale(.8);
}

.instructor-thumb-img :deep(svg) {
    width: 3.375rem;
    height: 3.375rem;
}

.zInput__input:not(:placeholder-shown) + .zInput__label {
    text-align: left;
}

@media all and (min-width: 65rem) {
    .contact-form {
        width: 27.375rem;
        margin: 0 auto;
    }

    .email-instructor {
        text-align: center;
    }
}
</style>
