<template>
    <div class="contact-button">
        <div
            v-if="!showIconOnly && showTooltip"
            class="tooltip-component"
        >
            <div class="tooltip-component__description">
                <ul>
                    <li
                        v-for="contactType in contactTypes"
                        :key="contactType"
                    >
                        <a
                            href="#"
                            class="contact-option"
                            @click="contactTypesDetails[contactType].action"
                        >
                            {{ contactType }}
                        </a>
                        <span
                            v-if="copiedToClipboardTooltip && contactType === AvailableContactTypes.SMS"
                            class="copied-to-clipboard"
                        >
                            {{ t.copiedToClipboard }}
                        </span>
                    </li>
                </ul>
            </div>
            <div class="tooltip-component__rectangle" />
        </div>
        <ZButton
            v-if="contactTypes.length"
            :variety="variety"
            :class="{ 'icon-only': showIconOnly }"
            :compact="compact"
            :wide="wide"
            @click="showContactOptions"
        >
            {{ (!showIconOnly || alwaysShowLabel) ? t.contactLabel : '' }}
            <template #icon>
                <component
                    :is="contactTypesDetails[contactTypes[0]].icon"
                    v-if="showIconOnly && showIcon"
                    class="icon"
                />
            </template>
            <span
                v-if="copiedToClipboardTooltip && contactTypes[0] === AvailableContactTypes.SMS"
                class="copied-to-clipboard"
            >
                {{ t.copiedToClipboard }}
            </span>
        </ZButton>
        <ContactInstructorHandler
            :t="t"
            :show-modal="canContactInstructorEmail === true"
            :requester-name="requesterName"
            :requester-email="requesterEmail"
            :instructor-pid="instructorPid"
            :instructor-display-name="instructorDisplayName"
            :instructor-profile-photo-url="instructorProfilePhotoUrl"
            :instructor-location="instructorLocation"
            :recaptcha-key="recaptchaKey"
            @closed="canContactInstructorEmail = false"
            @on-send-message="$emit('on-send-message', $event)"
            @toast:message="$emit('toast:message', $event)"
            @recaptcha:failure="$emit('recaptcha:failure', $event)"
        />
    </div>
</template>

<script lang="ts">

export enum AvailableContactTypes {
    WHATSAPP = 'Whatsapp',
    EMAIL = 'Email',
    SMS = 'SMS',
}

const translationDefaults = {
    contactLabel: 'Contact',
    copiedToClipboard: 'Copied !'
}

import { Ref, ref, defineComponent, computed, PropType } from 'vue';
import {validateTranslations} from "@ts/Util/i18n";
import { isMobileReactive } from '@ts/Util/responsiveness'
import EnvelopeSvg from '@icons/envelope.svg';
import WhatsappSvg from '@icons/whatsapp.svg';
import CommentSvg from '@icons/comment.svg';
import ZButton from '@components/Core/ZButton.vue';
import {post} from "@ts/Util/http"
import {csrfToken} from "@ts/Util/security"
import ContactInstructorHandler from '@modules/InstructorPage/ContactInstructorHandler.vue';

export default defineComponent({
    name: 'ContactButton',
    components: {
        EnvelopeSvg,
        WhatsappSvg,
        CommentSvg,
        ZButton,
        ContactInstructorHandler
    },
    props: {
        hasPhone: {
            type: Boolean,
            default: false,
        },
        hasEmail: {
            type: Boolean,
            default: false,
        },
        hasWhatsapp: {
            type: Boolean,
            default: false,
        },
        instructorPid: {
            type: String,
            required: true,
        },
        wide: {
            type: String,
            default: 'always',
            validator: (value: string) => {
                return [
                    'never',
                    'always',
                    'mobile-only',
                    'desktop-only',
                ].includes(value)
            },
        },
        compact: {
            type: String,
            default: 'desktop-only',
            validator: (value: string) => {
                return [
                    'never',
                    'always',
                    'mobile-only',
                    'desktop-only',
                ].includes(value)
            },
        },
        variety: {
            type: String,
            default: 'cyclops',
            validator: (value: string) => {
                // You can add more variety types here
                return [
                    'optimus',
                    'robin',
                    'phoenix',
                    'cyclops',
                    'rogue',
                    'love',
                    'plum',
                ].includes(value)
            },
        },
        alwaysShowLabel: {
            type: Boolean,
            default: false,
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
        t: {
            type: Object,
            required: true,
            validator: (value : Object) => validateTranslations(value, translationDefaults)
        },
        instructorContactAction: {
            type: String,
            required: false,
            default: ''
        },
        showModal: {
            type: Boolean,
            default: false
        },
        instructorDisplayName: {
            type: String,
            required: false,
            default: ''
        },
        instructorProfilePhotoUrl: {
            type: String,
            required: false,
            default: ''
        },
        instructorLocation: {
            type: String,
            required: false,
            default: ''
        },
        requesterName: {
            type: String,
            default: ''
        },
        requesterEmail: {
            type: String,
            default: ''
        },
        recaptchaKey: {
            type: String,
            default: ''
        },
        handleEmailExternally: {
            type: Boolean,
            default: false
        }
    },
    emits: ['option:email', 'option:whatsapp', 'option:sms', 'toast:message', 'on-send-message', 'recaptcha:failure', 'clicked:contact'],
    setup(props, { emit }) {
        const showTooltip = ref(false)
        const copiedToClipboardTooltip = ref(false)
        const isMobile: Ref<boolean> = isMobileReactive();
        const contactTypes = computed(() => {
            const available: string[] = []
            if (!props.instructorContactAction) {
                return available
            }
            if (props.hasWhatsapp) available.push(AvailableContactTypes.WHATSAPP)
            if (props.hasEmail) available.push(AvailableContactTypes.EMAIL)
            if (props.hasPhone && isMobile.value) available.push(AvailableContactTypes.SMS)
            return available
        });

        const isIOS = computed(() => {
            return /iPhone|iPad|iPod/i.test(navigator.userAgent)
        })
        const isAndroid = computed(() => {
            return /Android/i.test(navigator.userAgent)
        })

        const showIconOnly = computed(() => contactTypes.value.length === 1);
        const canContactInstructorEmail: Ref<Boolean> = ref(false)

        const contactTypesDetails = {
            Whatsapp: {
                action: async ($e) => {
                    $e.preventDefault()

                    try {
                        const ciResp = await post(
                            props.instructorContactAction,
                            csrfToken()
                        );

                        if (ciResp.status !== 200) {
                            throw new Error()
                        }

                        const ciRespJson = await ciResp.json()
                        if (!ciRespJson?.whats_app) {
                            throw new Error()
                        }

                        const whatsAppID = ciRespJson?.whats_app
                        let url = `https://api.whatsapp.com/send?phone=${whatsAppID}&text=Hi`
                        emit('option:whatsapp')
                        handleRedirect(url)
                    } catch {
                        console.error('Failed to open whatsapp')
                    } finally {
                        showTooltip.value = false
                    }
                },
                icon: WhatsappSvg
            },
            SMS: {
                action: async ($e) => {
                    $e.preventDefault()
                    if (!isMobile.value) {
                        return
                    }
                    try {
                        const ciResp = await post(
                            props.instructorContactAction,
                            csrfToken()
                        );

                        if (ciResp.status !== 200) {
                            throw new Error()
                        }

                        const ciRespJson = await ciResp.json()
                        if (!ciRespJson?.phone) {
                            throw new Error()
                        }

                        const phone = ciRespJson?.phone
                        handleRedirect(`sms:${phone}`)
                    } catch {
                        console.error('Failed to open Messages')
                    } finally {
                        showTooltip.value = false
                    }
                },
                icon: CommentSvg
            },
            Email: {
                action: ($e) => {
                    $e.preventDefault()
                    if (!props.handleEmailExternally) {
                        canContactInstructorEmail.value = true
                    }
                    emit('option:email');
                    showTooltip.value = false
                },
                icon: EnvelopeSvg
            }
        }

        const handleRedirect = (url) => {
            if (isMobile.value && isIOS.value) {
                // iOS does not open Messages or WhatsUp with window.open()
                window.location.href = url
            } else {
                window.open(url)
            }
        }

        const showContactOptions = ($e) => {
            emit('clicked:contact');
            if (contactTypes.value.length === 1) {
                return contactTypesDetails[contactTypes.value[0]].action($e)
            }

            showTooltip.value = !showTooltip.value;
        }

        return {
            isMobile,
            contactTypes,
            showTooltip,
            showContactOptions,
            contactTypesDetails,
            showIconOnly,
            copiedToClipboardTooltip,
            AvailableContactTypes,
            canContactInstructorEmail
        }
    }
})
</script>
<style scoped>

.contact-button {
    position: relative;
}

.icon-only :deep(.z-button){
    padding: 0 1rem;
}

.icon-only .icon {
    fill: var(--zumba-white);
}

.icon-only .love  .icon, .icon-only .phoenix .icon {
    fill: var(--zumba-plum);
}

.icon-only .cyclops .icon {
    fill: var(--zumba-gray-800);
}

.icon-only:hover .icon {
    fill: var(--zumba-white);
}

.button {
    border-radius: 0.2em;
}

.tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #333;
    color: #fff;
    padding: .416rem;
    border-radius: .416rem;
}
.tooltip ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.tooltip li {
    margin-bottom: .416rem;
}
.tooltip a {
    color: #fff;
    text-decoration: none;
}

.contact-option {
    text-transform: capitalize;
}

.copied-to-clipboard {
    font-size: 0.75rem;
    padding: 0.5rem;
}

.tooltip-component {
    color: var(--zumba-white);
    position: absolute;
    bottom: 100%;
    width: 100%;
}

.tooltip-component__description {
  background: var(--zumba-gray-600);
  border-radius: .2rem;
  padding: 0.8rem;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.tooltip-component__description ul {
  margin: 0;
  padding: 0;
}

.tooltip-component__description li {
  list-style-type: none;
  color: var(--zumba-white);
  padding: .375rem;
}

.tooltip-component__description a {
  color: var(--zumba-white);
}

.tooltip-component__rectangle:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: .625rem solid transparent;
    border-right: .625rem solid transparent;
    border-bottom: .625rem solid var(--zumba-gray-600);
    transform: rotate(180deg);
    position: relative;
    left: calc(50% - 10px);
}

@media all and (min-width: 48rem) {
    .tooltip-component {
        bottom: auto;
        top: 100%;
        display: flex;
        flex-direction: column-reverse;
        z-index: 1;
    }
    .tooltip-component__description {
        background: var(--zumba-gray-600);
        height: auto;
        border-radius: .2rem;
        padding: 0.8rem;
        padding-top: .75rem;
        padding-bottom: .75rem;
    }

    .tooltip-component__rectangle:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-left: .625rem solid transparent;
        border-right: .625rem solid transparent;
        border-bottom: .625rem solid var(--zumba-gray-600);
        transform: rotate(0deg)
    }
}

</style>
