<template>
    <div>
        <div
            ref="menu"
            class="menu-wrapper"
            :class="type"
            tabindex="-1"
            @blur="onBlur($event)"
        >
            <a
                href="javascript:void(0)"
                @click.prevent="onToggleClick"
            >
                <slot name="toggle">
                    <span class="menu-dots"><three-dots-vertical-icon /></span>
                </slot>
            </a>
            <transition name="menu-transition">
                <div
                    v-if="isOpen"
                    class="menu-contents"
                >
                    <ul class="ul">
                        <li
                            v-for="(item, index) in items"
                            :key="index"
                            class="li"
                            role="option"
                        >
                            <!-- note: use # as href fallback to enable keyboard navigation using tab -->
                            <a
                                :href="item.href || '#'"
                                :target="item.target || null"
                                :class="itemClass(item)"
                                @click="onItemClick($event, item)"
                                @blur="onBlur($event)"
                            >
                                <slot
                                    name="menu-item"
                                    :item="item"
                                >
                                    <span class="f-inter item-label">{{ item.label }}</span>
                                </slot>
                            </a>
                        </li>
                    </ul>
                    <slot name="after-menu-items" />
                </div>
            </transition>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, Ref, ref, watch } from 'vue'
import ThreeDotsVerticalIcon from '@bx-icons/regular/bx-dots-vertical-rounded.svg'

export interface MenuItem {
    /**
     * Translated text to use for the menu item label
     */
    label: string

    /**
     * Can be used by parent to identify specific menu items to handle click events on that item or rendering
     * special content for a specific item using menu-item slot
     */
    id?: string

    /**
     * Renders it disabled style and no click events are triggered
     */
    disabled?: boolean

    /**
     * Destructive action (styled red)
     */
    destructive?: boolean

    /**
     * Add a divider above the item on desktop view
     */
    divider?: boolean

    /**
     * If set, used to link to the given URL
     */
    href?: string,

    /**
     * Use in combination with href, to set the link target (e.g. _blank to open in new tab)
     */
    target?: string,
}

/**
 * This uses 3 dots for the trigger, and a dropdown for both mobile and desktop.
 *
 * Tip: Check out the MenuCombo if you need a mobile drawer when on mobile.  This one only does dropdown.
 */
export default defineComponent({
    name: 'MenuDropdown',
    components: {
        ThreeDotsVerticalIcon,
    },
    props: {
        items: {
            type: Array as PropType<MenuItem[]>,
            default: () => [],
        },
        type: {
            type: String,
            default: 'default',
            validator: (val: string) => ['default', 'wide'].includes(val)
        },
        startOpen: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['menu-item:click','menu:opened', 'menu:closed'],
    setup(props, context) {
        const menu: Ref<HTMLElement|null> = ref(null)
        const isOpen = ref(false)

        /**
         * Menu open/close main controls...
         */

        const onToggleClick = () => {
            if (isOpen.value) {
                isOpen.value = false
            } else {
                isOpen.value = true
                menu.value?.focus()
            }
        }

        if (props.startOpen) {
            onToggleClick()
        }

        const onBlur = (event:FocusEvent) => {
            if (!isOpen.value) {
                // already closed
                return
            }

            // relatedTarget is the clicked element - see if it is just changing focus to another part of the menu
            const relatedNode = event.relatedTarget as Element|null
            if (relatedNode && menu.value?.contains(relatedNode)) {
                // new focussed element is a child of the menu, not external focus
                return
            }

            // Blur was because of outside focus, close the menu
            isOpen.value = false
        }

        /**
         * Menu item controls...
         */

        const itemClass = (item: MenuItem) => {
            const classes = {
                'item-link': true,
                disabled: item.disabled,
                destructive: item.destructive,
                divider: item.divider,
            };
            if (props.type === 'wide') {
                classes['p-medium'] = true;
            }
            return classes;
        }

        const onItemClick = (event: Event, item: MenuItem) => {
            if (item.disabled) {
                // special case, it should not do anything if disabled
                event.preventDefault()
                return
            }
            // Note that a listener can use event.preventDefault() to stop the menu from closing after
            context.emit(
                'menu-item:click',
                {
                    itemId: item.id || null,
                    item,
                    event
                }
            )
            if (!item.href) {
                if (!event.defaultPrevented) {
                    // after clicking if no events prevented default, close the menu
                    isOpen.value = false
                }
                // keep it from navigating to # - we use # so keyboard navigation works
                event.preventDefault()
            }
        }

        watch(isOpen, (isOpen: boolean, oldState: boolean) => {
            if (isOpen !== oldState) {
                context.emit(`menu:${isOpen ? 'opened' : 'closed'}`)
            }
        });

        return {
            menu,
            onToggleClick,
            onItemClick,
            onBlur,
            isOpen,
            itemClass,
        }
    },
})
</script>

<style scoped lang="scss">
.menu-wrapper {
    position: relative;
    > a {
        display: flex;
        font-weight: 700;
        align-items: flex-end;
        text-decoration: underline;
        color: var(--zumba-gray-800);
    }
}

.menu-wrapper:focus {
    outline: none;
}

.item-label{
    font-weight: 700;
}

.menu-dots {
    color: var(--zumba-gray-800);
    display: inline-flex;
    height: 1rem;
    width: 1rem;
}

.menu-contents {
    position: absolute;
    right: 0;
    top: calc(100% + .5rem);
    z-index: 200;
    font-size: .8rem;
    padding: .75rem 1.5rem;
    box-shadow: 0 0 1rem var(--zumba-gray-200);
    border: thin solid var(--zumba-gray-200);
    border-radius: 1rem;
    color: var(--zumba-gray-800);
    background-color: var(--zumba-white);
}

@media all and (max-width: 48rem) {
    .menu-wrapper.wide {
        position: unset;
    }
    .menu-wrapper.wide .menu-contents {
        box-shadow: none;
        border-radius: 0;
        top: 4.7rem;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 75vh;
    overflow-y: auto;
}

.li {
    margin: 0;
    display: flex;
    align-items: center;
}

.item-link {
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    padding: .75rem;
    text-decoration: none;
    color: var(--zumba-gray-800);
    align-items: center;
    white-space: nowrap;
}

@media all and (max-width: 48rem) {
    .menu-wrapper.wide .item-link {
        line-height: 27px;
    }
}

.item-link:hover {
    color: var(--zumba-gray-800);
}

.item-link.disabled,
.item-link.disabled:hover {
    color: var(--zumba-gray-800);
    cursor: not-allowed;
}

.item-link.destructive,
.item-link.destructive:hover {
    color: var(--zumba-light-coral);
}

.item-link.divider {
    margin-top: .9rem;
    padding-top: 1.5rem;
    border-top: solid 1px var(--zumba-gray-200);
}

.menu-transition-enter-active,
.menu-transition-leave-active {
    transition: all .3s ease-in-out;
    transform-origin: top right;
}

.menu-transition-enter-from,
.menu-transition-leave-to {
    opacity: 0;
    transform: scale(0);
}
</style>
