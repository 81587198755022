<template>
    <Modal
        :show="show && !showContactModal"
        mobile-drawer
        close-when-click-on-background
        :show-close-button="true"
        :custom-styles="{modal: {'max-width': '76.875rem', 'width': '40%'}}"
        @closed="$emit('closed')"
    >
        <div class="no-direct-payment">
            <template v-if="props.loading">
                <Loading />
            </template>
            <template v-else>
                <div
                    class="no-direct-payment__content"
                >
                    <h5 class="z-h5 no-direct-payment__content__title">
                        <b>{{ t.title }}</b>
                    </h5>
                    <div class="no-direct-payment__content__wrapper">
                        <div>
                            <p class="z-b4 f-inter no-direct-payment__content__subtitle">
                                {{ t.subtitle }}
                            </p>
                        </div>

                        <div v-if="hasFreePass">
                            <span class="z-b5 f-inter no-direct-payment__content__free-pass">
                                {{ t.freePassText }}
                            </span>
                        </div>
                    </div>
                    <p class="z-b4 f-inter no-direct-payment__content__description">
                        {{ descriptionText }}
                    </p>
                    <div class="no-direct-payment__content__info">
                        <p
                            v-if="nameAndStreetText"
                            class="z-b4 f-inter"
                        >
                            {{ nameAndStreetText }}
                        </p>
                        <p
                            v-if="locationLocality && locationPostalCode"
                            class="z-b4 f-inter"
                        >
                            {{ `${locationLocality}, ${locationPostalCode}` }}
                        </p>
                        <p class="z-b4 f-inter">
                            {{ phone }}
                        </p>
                    </div>
                </div>

                <div
                    class="no-direct-payment__buttons"
                    :class="{'two-col': bookingUrl}"
                >
                    <div class="no-direct-payment__contact">
                        <ContactButtonHandler
                            always-show-label
                            :t="t"
                            :has-phone="instructor.hasPhone"
                            :has-email="instructor.hasEmail"
                            :has-whatsapp="instructor.hasWhatsapp"
                            :instructor-pid="instructor.pid"
                            :instructor="instructor"
                            :instructor-contact-action="`${instructorContactActionBase}/${instructor.pid}`"
                            handle-email-externally
                            variety="phoenix"
                            @option:email="showContactModal = true"
                        />
                    </div>
                    <div
                        v-if="bookingUrl"
                        class="no-direct-payment__book-url"
                    >
                        <z-button
                            variety="optimus"
                            compact="desktop-only"
                            @click="redirectToExitPage"
                        >
                            {{ t.locationWebsite }}
                        </z-button>
                    </div>
                </div>
                <div
                    v-if="startDate && endDate && title && descriptionText"
                    class="no-direct-payments__add-to-cal"
                >
                    <AddToSchedule
                        :t="t"
                        :show-dropdown="showAddToScheduleDropdown"
                        :event-name="title"
                        :event-description="descriptionText"
                        :start-date="new Date(startDate)"
                        :end-date="new Date(endDate)"
                        :location="formattedAddress"
                        @add-to-schedule="handleAddToSchedule"
                    />
                </div>
            </template>
        </div>
    </Modal>
    <ContactInstructorHandler
        :t="t"
        :show-modal="showContactModal && show"
        :instructor="instructor"
        :requester-name="requesterName"
        :requester-email="requesterEmail"
        :recaptcha-key="recaptchaKey"
        @closed="showContactModal = false"
        @on-send-message="$emit('on-send-message', $event)"
        @toast:message="$emit('toast:message', $event)"
    />
</template>

<script lang="ts">
const localT = {
    title: 'Count me in!',
    subtitle: 'Requires a membership',
    description: 'A membership is required to access this class. Contact the location for more info. Free passes are available at location.',
    contactLabel: 'Contact',
    copiedToClipboard: 'Copied!',
    freePassText: 'Free pass available',
    website: 'Website',
    locationWebsite: 'Location website',
    addToSchedule: 'Add to schedule',
    addedToSchedule: 'Added to schedule',
    emailSubmitted: 'Email sent to your instructor'
}
</script>

<script lang="ts" setup>
import {PropType, computed, ref} from "vue"
import Modal from "@components/Core/Modal.vue"
import {validator} from "@ts/Util/i18n"
import ContactButtonHandler from "@components/ContactButton/ContactButtonHandler.vue"
import ZButton from '@components/Core/ZButton.vue'
import Loading from '@components/Util/Loading.vue'
import AddToSchedule from "@components/ClassDetails/AddToSchedule.vue"
import { LEAD_SOURCES } from "@ts/Util/Leads"
import {Instructor} from "@modules/InstructorPage/InstructorCover.vue"
import ContactInstructorHandler from '@modules/InstructorPage/ContactInstructorHandler.vue'
import { mixpanelServiceTrack } from '@ts/Util/analytics'

const props = defineProps({
    t: {
        type: Object as PropType<typeof localT>,
        default: () => localT,
        validator: validator(localT)
    },
    show: {
        type: Boolean,
        default: false
    },
    loading: {
        type: Boolean,
        default: false
    },
    hasFreePass: {
        type: Boolean,
        required: false,
        default: false
    },
    phone: {
        type: String,
        required: false,
        default: '',
    },
    email: {
        type: String,
        required: false,
        default: ''
    },
    locationDescription: {
        type: String,
        required: false,
        default: ''
    },
    locationStreet: {
        type: String,
        required: false,
        default: ''
    },
    locationLocality: {
        type: String,
        required: false,
        default: ''
    },
    locationPostalCode: {
        type: String,
        required: false,
        default: ''
    },
    formattedAddress: {
        type: String,
        required: false,
        default: ''
    },
    bookingUrl: {
        type: String,
        required: false,
        default: ''
    },
    goBackUrl: {
        type: String,
        required: false,
        default: ''
    },
    redirectUrl: {
        type: String,
        required: false,
        default: ''
    },
    classId: {
        type: String,
        required: false,
        default: ''
    },
    whatsapp: {
        type: String,
        required: false,
        default: ''
    },
    title: {
        type: String,
        required: false,
        default: ''
    },
    description: {
        type: String,
        required: false,
        default: ''
    },
    startDate: {
        type: String,
        required: false,
        default: ''
    },
    endDate: {
        type: String,
        required: false,
        default: ''
    },
    showAddToScheduleDropdown: {
        type: Boolean,
        required: false,
        default: false
    },
    instructor: {
        type: Object as PropType<Instructor>,
        required: true
    },
    instructorContactActionBase: {
        type: String,
        required: false,
        default: ''
    },
    recaptchaKey: {
        type: String,
        default: ''
    },
    requesterName: {
        type: String,
        default: ''
    },
    requesterEmail: {
        type: String,
        default: ''
    },
})

const emit = defineEmits(['closed', 'create-lead', 'schedule-class', 'on-send-message', 'toast:message'])

const showContactModal = ref(false)

const descriptionText = computed<string>(() => props.description || props.t.description)

const redirectToExitPage = () => {
    mixpanelServiceTrack('TrackClickedNotRegisterableClass', {
        locationWebsite: props.bookingUrl,
    })
    emit('closed')
    window.open(props.redirectUrl, '_blank')
}

const nameAndStreetText = computed(() => `${props.locationDescription} ${props.locationStreet}`.trim())

const handleAddToSchedule = () => {
    emit('schedule-class');
    emit('create-lead', LEAD_SOURCES.ADD_TO_SCHEDULE);
}
</script>

<style scoped lang="scss">

.no-direct-payment__content__title {
    display: none;
    text-transform: uppercase;
}

.no-direct-payment__content__subtitle {
    font-weight: 700;
    color: var(--zumba-gray-800);
    margin: 0;
}

.no-direct-payment__content__info-link {
    text-decoration: underline;
    color: var(--zumba-gray-800);
}

.no-direct-payment__content__free-pass {
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    background-color: var(--zumba-gray-200);
    display: flex;
    align-items: center;
    font-weight: 700;
    color: var(--zumba-gray-800);
    margin-top: .5rem;
}

.no-direct-payment__content__info,
.no-direct-payment__content__description {
    color: var(--zumba-gray-700);
}

.no-direct-payment__content__description {
    margin: 1.25rem auto;
}

.no-direct-payment__content__info {
    margin-bottom: 2rem;
}

.no-direct-payment__content__info p {
    margin: 0;
    color: var(--zumba-gray-700);
}

.no-direct-payment__book-url {
    margin-top: 1rem;
}

:deep(.added-to-schedule-dropdown) {
    display: flex;
    justify-content: center;
    align-items: center;
}

:deep(.menu-wrapper > .menu-contents) {
    @media only screen and (max-width: 48em) {
        top: calc(100% - 14.5rem);
    }
}

:deep(.menu-wrapper > a) {
    display: inline-flex;
    text-decoration: none;
    color: var(--zumba-plum);
}

.no-direct-payments__add-to-cal {
    text-align: center;
    margin-top: 1rem;
}

@media all and (min-width: 48rem) {
    .no-direct-payment__content__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .no-direct-payment__content__title {
        margin: 0.5rem 0 1.5rem 0;
        display: block;
    }

    .no-direct-payment__content__free-pass {
        margin-top: 0;
    }

    .no-direct-payment__buttons.two-col {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap:.5rem;
    }

    .no-direct-payment__book-url {
        margin-top: 0;
    }
}
</style>
