<template>
    <div 
        class="profile-image-container"
    >
        <img
            v-if="profilePhotoUrl"
            :src="profilePicThumbnail(profilePhotoUrl, 146)"
            :srcset="profilePicThumbnail(profilePhotoUrl, 146) + ' 1x,' +
                profilePicThumbnail(profilePhotoUrl, 292) + ' 2x'"
            :alt="t.profileImageAlt"
            :style="customStyles"
            class="profile-image-img"
        >
        <DefaultProfilePic 
            v-else
            class="profile-default-image-img" 
        />
    </div>
</template>

<script lang="ts">
import { defineComponent,  } from 'vue'
import {validateTranslations} from "@ts/Util/i18n"
import { profilePicThumbnail } from '@ts/Util/profile_pic';
import DefaultProfilePic from "@images/default-profile-pic.svg"

const translationDefaults = {
    profileImageAlt: "Instructor picture",
};

export default defineComponent({
    name: 'ProfilePictureDefault',
    components: {
        DefaultProfilePic
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value : Object) => validateTranslations(value, translationDefaults)
        },
        profilePhotoUrl: {
            type: String,
            default: '',
        },
        style: {
            type: Object,
            default: () => ({})
        }
    },
    setup(props) {
        return {
            profilePicThumbnail,
        }
    },
    computed: {
        customStyles() {
            return this.style;
        }
    }
});


</script>

<style scoped>
.profile-image-img {
    display: block;
    height: auto;
    width: auto;
    top: 50%;
    left: 50%;
    border-radius: 50%;
}
</style>

  